window.loaded = false;
var windowWidth = 0;
var homeH1 = undefined;
var aboutUsInner = undefined;
var trustBoxes = undefined;

$(window).on('load', function(){
	window.loaded = true;
});

var headerBorder = undefined;

var mapContent = undefined;
var breakBoxCheck = false;
var boxCounter = 0;

$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;
	var resizeFn = function() {
		if (windowWidth != window.innerWidth) {
			windowWidth = window.innerWidth;
			
			if (typeof site_id !== 'undefined' && site_id == 1) {
				checkHomeH1Position();
			}

			$(document).trigger('hsa_landing.window_width_changed', windowWidth);
		}
	}

	window.addEventListener('resize', resizeFn);

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	$('[data-purpose="navigation-link"]').each(function(){
		setNavigationUrl($(this));
	});
	
	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});
	
	if( $('.cookie-bar').length > 0 ) {

		$('.cookie-bar .button').click(function(){
			$('.cookie-bar').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		var timeout = $(this).data('timeout');
		if (isNaN(timeout)) {
			timeout = 400;
		}
		
		if (item.length > 0) {
			$('html, body').animate({scrollTop: item.offset().top}, timeout);
		}
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});

	
	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			homeH1 = $('[data-purpose="floating-h1"]');
			aboutUsInner = $('[data-purpose="about-us-inner"]');
			if (homeH1.length > 0 && aboutUsInner.length > 0) {
				if (window.innerWidth >= 1024 || window.innerHeight >= 1024) {
					var scrollFn = function() {
						checkHomeH1Position();
					}

					window.addEventListener('touchend', scrollFn);
					window.addEventListener('touchmove', scrollFn);
					window.addEventListener('scroll', scrollFn);
				}
			}
		}
	}

	trustBoxes = $('[data-purpose="trust-box"]');
	if (trustBoxes.length > 0) {
		checkTrustBoxPosition();

		var boxScrollFn = function() {
			if (breakBoxCheck) {
				return;
			}
			checkTrustBoxPosition();
		}

		window.addEventListener('touchend', boxScrollFn);
		window.addEventListener('touchmove', boxScrollFn);
		window.addEventListener('scroll', boxScrollFn);
	}

	if (typeof articleGalleryItems !== 'undefined') {
		$('.article-gallery-header-item').click(function(){
			openArticleGallery($(this));
		});
	}

	$('[data-purpose="save-pass-button"]').click(function(){
		var _self = this;
		var sendedForm = false;

		if (!sendedForm) {
			sendedForm = true;
			var data = {
				lang: frontend_lang
			};

			var notyf = new Notyf({
                delay:5000
            });

			var form = $('#new-pass-form');

			form.serializeArray().forEach(function(field){
				data[field.name] = field.value
			});
			$.ajax({
				url:form.attr('action'),
				dataType:'json',
				type:'post',
				data: data,
				beforeSend: function() {
					form.find('.has-error').removeClass('has-error');
				},
				success: function(response) {
					if (response.success) {
						if ((response.msg).trim() !== '') {
							notyf.confirm(response.msg);
						}
						setTimeout(function(){
							window.location.href = response.url;
						}, 2000);
					} else {
						if (typeof response.errors == 'object') {
							$.each(response.errors, function(key, value) {
								if (value.trim() !== '') {
									console.log(value);
									notyf.alert(value);
								}

								var input = form.find('[name="'+key+'"]');
								if (input.length > 0) {
									input.addClass('has-error');
								}
							});
						}

						setTimeout(function() {
							sendedForm = false;
						}, 500);
					}
				},
			});
		}
	});

	$('[data-purpose="logout-button"]').click(function(){
		$.ajax({
			url: ajax_controller+'logout',
			dataType:'json',
			type:'post',
			data: {},
			success: function(response) {
				document.location.reload();
			}
		})
	});
});

function checkTrustBoxPosition() {
	var scrollTop = $(window).scrollTop();

	$.each(trustBoxes, function(){
		if (!$(this).hasClass('active')) {
			var top = $(this).offset().top;

			if ((top + 100) < scrollTop + window.innerHeight) {
				$(this).addClass('active');
			}
		}
	});

	if (boxCounter == trustBoxes.length) {
		breakBoxCheck = true;
	}
}

function checkHomeH1Position() {
	if (!windowWidth) {
		windowWidth = window.innerWidth;
	}

	var clear = true;
	if (windowWidth >= 1024) {
		var scrollTop = $(window).scrollTop();
		var height = homeH1.outerHeight();
		var top = aboutUsInner.offset().top;
		var newTop = scrollTop - top;
		var border = aboutUsInner.outerHeight() - height;

		if (newTop >= 0) {
			clear = false;
			if (newTop >= 0 && border >= newTop && !homeH1.hasClass('fixed')) {
				if (homeH1.hasClass('absolute')) {
					homeH1.removeClass('absolute');
				}
				homeH1.addClass('fixed')
			} else if (newTop < 0 && homeH1.hasClass('fixed')) {
				homeH1.removeClass('fixed');
				console.log('cicaaa');
			} else if (border < newTop && !homeH1.hasClass('absolute')) {
				if (homeH1.hasClass('fixed')) {
					homeH1.removeClass('fixed');
				}

				homeH1.addClass('absolute');
			}
		}
	} 

	if (clear) {
		if (homeH1.hasClass('fixed')) {
			homeH1.removeClass('fixed');
		}

		if (homeH1.hasClass('absolute')) {
			homeH1.removeClass('absolute');
		}
	}
}


function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}
		
		gtag('event', action, evtData);
	}
	else if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}

function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');
	
	if (address) {
		var protocol = 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if(device == 'iphone' || device == 'ipad' || device == 'ipod'){
			function iOSversion() {
					
				if (/iP(hone|od|ad)/.test(navigator.platform)) {
				  var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}
		
		var urlSuffix = '/?daddr='+address;
		var nav_url = '';
		switch(protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}


function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');
	
	if (typeof galleryItems === 'object' && typeof galleryItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, galleryItems[groupId], index);
	} 
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) 
{
	if (!index) {
		index = 0;
	}

	$(target).lightGallery({
		dynamic:true,
		dynamicEl:items,
		index:index,
		hash:false,
		download: false
	});
}

function getMapStyle()
{
	return [];
}

function initGalleries()
{
	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="gallery-box-item"]:not(.initialized)').each(function(){
			$(this).addClass('initialized');
			$(this).click(function(){
				var id = $(this).data('group-id');
				if (id && typeof mediaGroupItems[id] !== 'undefined') {
					initGallery($(this), mediaGroupItems[id]);
				}
			});
		});
	}
}


