(function(window){
    function ImageLoader() {
        try {
            this.init();
        } catch (error) {
            console.error(error);
        }
    };

    ImageLoader.prototype = {
        parallaxItems:[],
        init: function() {
          this.loadImages();
          this.setElements();
          this.addDOMEvents();
        },
        setElements: function() {
            var _self = this;
            $('[data-purpose="parallax-content"]').each(function(){
                _self.parallaxItems.push($(this));
            });

        },
        loadImages: function() {
            this.loadImageItems();
            this.loadImageBackgrounds();
        },
        addDOMEvents: function() {
            var _self = this;
            
            if (this.parallaxItems.length > 0 && !isTouchDevice()) {
                $(window).on('scroll', function(){
                    _self.checkParallax();
                });
            }
        },
        checkParallax: function() {
            var _self = this;
            
            console.log('parallax-ot egyedileg le kell kezelni');
        },
        loadImageItems: function() {
            $('[data-purpose="after-load-image"]').each(function(){
                var src = $(this).data('src');
                if (src) {
                    $(this).attr('src', src);
                    var parentClass = $(this).data('extra-parent-class');
                    $(this).on('load', function(){
                        $(this).removeAttr('data-purpose');
                        $(this).addClass('loaded');

                        if (parentClass) {
                            $(this).parent().addClass(parentClass);
                        }
                    });
                    
                }
            });
        },
        loadImageBackgrounds: function() {
            var _self = this;
            $('[data-purpose="image-bg-load"]:not(.initialized)').each(function(){
                var image = $(this).data('image');
                if (image) {
                    var parentClass = $(this).data('extra-parent-class');
                    var style = $(this).attr('style');
                    if (!style) {
                        style = '';
                    }

                    style += 'background-image:url("'+image+'")';
                    $(this).attr('style', style);

                    if (parentClass) {
                        var parentSelector = $(this).data('parent-selector');
                        if (parentSelector) {
                            $(this).parents(parentSelector).addClass(parentClass);
                        } else {
                            $(this).parent().addClass(parentClass);
                        }
                    }
                }

                $(this).addClass('initialized');
            });
        }
    };

    window.ImageLoader = ImageLoader;
})(window);
