(function(wnd){
    function LoginForm(form, params) {
        try {
            if (!form) {
                throw 'Form is required!';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    };

    LoginForm.prototype = {
        form: undefined,
        notyf: undefined,
        button: undefined,
        sendedForm: false,
        forgotButton: false,
        forgotRemodal: false,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.notyf = new Notyf({
                delay:5000
            });
            this.button = this.form.find('[data-purpose="login-button"]');                        
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.button.length > 0) {
                this.button.click(function(){
                    _self.sendForm();
                });
            }

            if (this.forgotButton.length > 0) {
                this.forgotButton.click(function(){
                    _self.sendForgotForm();
                });
            }

            this.form.find('input').keypress(function(e){
                var key = typeof e.keyCode !== 'undefined' ? e.keyCode : (typeof e.charCode !== 'undefined' ? e.charCode : undefined);
                
                if (key == 13) {
                    _self.sendForm();
                    e.preventDefault();
                }
            });
        },
        setParams: function(params) {

        },
        sendForm: function() {
            var _self = this;

            if (!this.sendedForm) {
                this.sendedForm = true;
                var data = {
                    lang: frontend_lang
                };

                this.form.serializeArray().forEach(function(field){
                    data[field.name] = field.value
                });
                $.ajax({
                    url:this.form.attr('action'),
                    dataType:'json',
                    type:'post',
                    data: data,
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            if ((response.msg).trim() !== '') {
                                _self.notyf.confirm(response.msg);
                            }
                            setTimeout(function(){
                                document.location.reload();
                            }, 2000);
                        } else {
                            if (typeof response.errors == 'object') {
                                $.each(response.errors, function(key, value) {
                                    if (value.trim() !== '') {
                                        _self.notyf.alert(value);
                                    }

                                    var input = _self.form.find('[name="'+key+'"]');
                                    if (input.length > 0) {
                                        input.addClass('has-error');
                                    }
                                });
                            }

                            setTimeout(function() {
                                _self.sendedForm = false;
                            }, 500);
                        }
                    },
                });
            }
        },
        sendForgotForm: function() {
            var forgotForm = $('[data-remodal-id=forgot-remodal]').find('#forgotForm');

            var _self = this;

            if (!this.sendedForm) {
                this.sendedForm = true;
                var data = {
                    lang: frontend_lang
                };
            }

            forgotForm.serializeArray().forEach(function(field){
                data[field.name] = field.value
            });

            $.ajax({
                url:forgotForm.attr('action'),
                dataType:'json',
                type:'post',
                data: data,
                beforeSend: function() {
                    _self.form.find('.has-error').removeClass('has-error');
                },
                success: function(response) {
                    if (response.success) {
                        if ((response.msg).trim() !== '') {
                            _self.notyf.confirm(response.msg);
                        }
                        setTimeout(function(){
                            document.location.reload();
                        }, 2000);
                    } else {
                        if (typeof response.errors === 'object') {
                            $.each(response.errors, function(key, value) {
                                if (value.trim() !== '') {
                                    _self.notyf.alert(value);
                                }

                                var input = _self.form.find('[name="'+key+'"]');
                                if (input.length > 0) {
                                    input.addClass('has-error');
                                }
                            });
                        }

                        setTimeout(function() {
                            _self.sendedForm = false;
                        }, 500);
                    }
                },
            });            
        }
    };

    wnd.LoginForm = LoginForm;
})(window);
