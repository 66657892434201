(function(window){
    function Applicant(container, params){    
        this.container = container;
        
        if (params) {
            this.params = params;
        }    

        this.init();
    }

    Applicant.prototype = {
        container: undefined,
        editableCol: undefined,
        notyf: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();            
        },
        setElements: function() {
            this.editableCol = this.container.find('.comment-content');            
            this.notyf = new Notyf({
                delay:5000
            });
        },
        addDOMEvents: function() {
            $('.doublescroll').doubleScroll();
            var _self = this;
            if (typeof this.editableCol !== undefined) {
                _self.editableCol.click(function() {
                    if (!$(this).hasClass('opened')) {
                        var actual = $(this);
                        $(this).html('<div class="input-outer"><input class="field comment" name="'+actual.data('name')+'" value="'+$(this).data('value')+'"></div>');
                        $(this).find('.input-outer').append('<a class="save_btn button">Mentés</a>');
                        $(this).find('.input-outer').append('<a class="close_btn button">Mégse</a>');
                        $(this).addClass('opened');
                        $(this).find('input').focus();

                        $(this).find('.save_btn').click(function(){
                            _self.saveData(actual.data('id'), actual.find('input').val(), actual, actual.data('name'));
                            actual.html(actual.find('input').val());
                        });

                        actual.find('input').keypress(function(e){
                            var key = typeof e.keyCode !== 'undefined' ? e.keyCode : (typeof e.charCode !== 'undefined' ? e.charCode : undefined);
                            
                            if (key == 13) {
                                _self.saveData(actual.data('id'), actual.find('input').val(), actual, actual.data('name'));
                                actual.html(actual.find('input').val());
                                e.preventDefault();
                            }
                        });
                        
                        $(this).find('.close_btn').click(function(){
                            _self.cancelData(actual);
                            actual.html(actual.data('value'));                            
                            actual.append('<img src="'+base_url+'assets/img_admin/edit.png" class="edit-button" alt="Módosítás" title="Módosítás">');            
                            return false;                            
                        });

                        var obj = document.getElementById('ApllicantsTable');
                        obj.scrollLeft = obj.scrollWidth - obj.clientWidth;
                    }
                });
            }
        },
        saveData: function(id, data, field, field_name) {
            var _self = this;

            $.ajax({
                url: ajax_controller+'saveComment',
                dataType: 'json',
                type: 'post',
                data: {id: id, data: data, field_name: field_name},
                success: function(response) {
                    _self.notyf.confirm(response.msg, 3000);
                    _self.oldValue = data;
                    field.removeClass('opened');
                    field.data('value', data);
                    field.append('<img src="'+base_url+'assets/img_admin/edit.png" class="edit-button" alt="Módosítás" title="Módosítás">');
                }                    
            });
        },
        cancelData: function(field){
            field.removeClass('opened');            
        }
    };

    window.Applicant = Applicant;
})(window);