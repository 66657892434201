(function(window){
    function FormHandler(form, params){    
        this.form = form;
        
        if (params) {
            this.params = params;
        }    

        this.init();
    }

    FormHandler.prototype = {
        form: undefined,
        button: undefined,
        sendedForm: false,
        notyf: undefined,
        feedbackContent: undefined,
        container: undefined,
        init: function() {
            this.setElements();
            this.addDOMEvents();            
        },
        setElements: function() {
            this.container = this.params.container;
            this.button = this.form.find('.button');
            this.feedbackContent = this.container.find('[data-purpose="feedback-content"]');
            this.notyf = new Notyf({
                delay:5000
            });
        },
        addDOMEvents: function() {
            var _self = this;
            this.button.click(function(e){
                e.preventDefault();
                _self.sendForm();
            });

            $(document).on('change', '.form-file', function(){
                _self.uploadFile(this.files, $(this).data('hidden-name'));
            });
        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;

                $.ajax({
                    url:ajax_controller+'pageForm',
                    dataType:'json',
                    type:'post',
                    data:this.form.serialize(),
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(response) {
                        if (response.success) {
                            _self.form.hide();
                            _self.feedbackContent.show();
                            _self.form.remove();

                            if (typeof response.msg === 'string' && (response.msg).trim() !== '') {
                                _self.notyf.confirm(response.msg);
                            }

                        } else {
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(name, text){
                                    if (text && text.trim() !== '') {
                                        _self.notyf.alert(text);
                                    }

                                    var field = _self.form.find('[name="'+name+'"]');
                                    
                                    if (field.length > 0) {
                                        if (field.is('select')) {
                                            field.parent().addClass('has-error');
                                        } else {
                                            field.addClass('has-error');
                                        }
                                    }
                                });
                            }
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 1000);
                    }
                });
            }
        },
        uploadFile: function(files, hidden_name) {
            var data = new FormData();
            data.append('file', files[0]);
            
            $.ajax({
                url:ajax_controller+'uploadTmpFile/'+frontend_lang,
                type:'post',
                dataType:'json',
                data: data,
                cache: false,
                contentType: false,
                processData: false,
                success: function(response) {
                    
                    if (response.success) {
                        $('[name="'+hidden_name+'"]').val(response.tmp_file);
                    } else {
                        showMessageWindow(response.error);
                    }
                }
            });
            
        },
    };

    window.FormHandler = FormHandler;
})(window);